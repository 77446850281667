import React from "react"
import { Link } from "gatsby"
import { body } from "./content.module.css"
import { box } from "./content.module.css"
import { title } from "./content.module.css"

export default (props) => (
    <Link to={props.path}>
      <div className={box}>
        <div className={title}>
          {props.title}
        </div>
        <div className={body}>
          {props.body}
        </div>
      </div>
    </Link>
)
