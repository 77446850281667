import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { contents } from "./contents.module.css"
import { contentsHeader } from "./contents.module.css"
import Content from "../components/content"

const asContent = function(edge)
{
    const id = edge.node.id
    const title = edge.node.frontmatter.title
    const body = edge.node.excerpt
    const path = '/blogs' + edge.node.fields.slug

    return <Content id={id} title={title} body={body} path={path} />
}

export default () => {
    const data = useStaticQuery(graphql`
query {
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(fromNow: true)
        }
        fields {
          slug
        }
        excerpt(pruneLength: 60 truncate: true)
      }
    }
  }
}
`)

    return (
    <div className={contents}>
      <div className={contentsHeader}>
        Blogs
        <hr />
      </div>
      {data.allMarkdownRemark.edges.map(asContent)}
    </div>
    )
}
