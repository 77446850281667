import React from "react"
import Contents from "../components/contents"
import MainLayout from "../components/mainlayout"
import SEO from "../components/seo.js"

export default () => (
    <MainLayout>
      <SEO title="Home" />
      <Contents />
    </MainLayout>
)
